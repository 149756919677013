import { match } from 'ts-pattern'
import { type PersonType } from './types'
import { PlusMinusButton } from './PlusMinusButton'

export interface PersonTypeProps {
  type: PersonType
  count: number
  isPlusDisable?: boolean
  isMinusDisable?: boolean
  handlePlusClick: VoidFunction
  handleMinusClick: VoidFunction
}

export const PersonTypeItem = ({
  type,
  count,
  isPlusDisable,
  isMinusDisable,
  handlePlusClick,
  handleMinusClick,
}: PersonTypeProps) => {
  const personTypeInfo = match(type)
    .with('ADULT', () => ({ name: '성인', description: '만 13세 이상' }))
    .with('CHILD', () => ({ name: '소아', description: '만 13세 미만' }))
    .with('INFANT', () => ({ name: '유아', description: '24개월 미만' }))
    .exhaustive()

  const { name, description } = personTypeInfo

  return (
    <div className="tw-flex tw-justify-between tw-px-14 tw-py-8">
      <strong className="title2 tw-font-normal">
        {name}
        <p className="subtitle4 tw-font-normal tw-text-text-secondary">
          {description}
        </p>
      </strong>
      <div className="tw-flex tw-items-center tw-gap-x-16">
        <PlusMinusButton
          buttonType="minus"
          disabled={isMinusDisable}
          onClick={handleMinusClick}
        />
        <span className="body1 tw-mt-2 tw-font-semibold tw-text-text-primary">
          {count}
        </span>
        <PlusMinusButton
          buttonType="plus"
          disabled={isPlusDisable}
          onClick={handlePlusClick}
        />
      </div>
    </div>
  )
}
