import type { DateMatrixProps } from '.'

interface PrevDatesProps
  extends Pick<DateMatrixProps, 'currentYear' | 'currentMonthIndex'> {
  monthlyActualDate: Date
}

interface DisabledDatesProps
  extends Pick<PrevDatesProps, 'monthlyActualDate'> {}

interface BlankDatesProps extends Pick<PrevDatesProps, 'monthlyActualDate'> {}

const DisabledDates = ({ monthlyActualDate }: DisabledDatesProps) => {
  return (
    <>
      {Array.from(
        {
          length: new Date(
            monthlyActualDate.getFullYear(),
            monthlyActualDate.getMonth() - 1,
            1
          ).getDay(),
        },
        (_, disabledDateIndex) => (
          <div
            key={disabledDateIndex}
            className="tw-h-50 tw-flex tw-w-44 tw-items-start tw-justify-center">
            <div className="tw-flex tw-h-36 tw-w-36 tw-items-start tw-justify-center tw-pt-4">
              <p className="subtitle2 tw-text-grey040">
                {new Date(
                  monthlyActualDate.getFullYear(),
                  monthlyActualDate.getMonth(),
                  -disabledDateIndex
                ).getDate()}
              </p>
            </div>
          </div>
        )
      ).reverse()}
    </>
  )
}

const BlankDates = ({ monthlyActualDate }: BlankDatesProps) => {
  return (
    <>
      {Array.from(
        {
          length: new Date(
            monthlyActualDate.getFullYear(),
            monthlyActualDate.getMonth() - 1,
            1
          ).getDay(),
        },
        (_, i) => (
          <div key={i} />
        )
      )}
    </>
  )
}

export const PrevDates = ({
  currentYear,
  currentMonthIndex,
  monthlyActualDate,
}: PrevDatesProps) => {
  const isFirstMonth =
    currentYear === monthlyActualDate.getFullYear() &&
    currentMonthIndex + 1 === monthlyActualDate.getMonth()

  return (
    <>
      {isFirstMonth ? (
        <DisabledDates monthlyActualDate={monthlyActualDate} />
      ) : (
        <BlankDates monthlyActualDate={monthlyActualDate} />
      )}
    </>
  )
}
