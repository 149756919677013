import { useCreateMemberSessionMutation } from '@src/views/Login/mutations'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { usePageQuery } from './usePageQuery'

/**
 *
 * @param isLoggedIn 로그인 상태
 * @param successCallback 회원 세션 생성 성공 콜백
 * @param errorCallback 회원 세션 생성 실패 콜백
 *
 *
 * @returns isPending 회원 세션 생성 api Loading 상태
 * 소셜 로그인 후 회원 세션을 생성하는 Effect를 제공합니다.
 */
export const useSocialSignUp = ({
  isLoggedIn,
  successCallback,
  errorCallback,
}: {
  isLoggedIn: boolean
  successCallback?: VoidFunction
  errorCallback?: VoidFunction
}) => {
  const router = useRouter()
  const { KSESID } = usePageQuery<Record<string, string>>()
  const isFromNaver = KSESID ? true : false

  const { data: session, update: updateSession } = useSession()
  const [isPending, setIsPending] = useState(false)
  const { mutate: createMemberSessionMutate } =
    useCreateMemberSessionMutation(isFromNaver)

  const socialSignUpUrl = process.env.NEXT_PUBLIC_SOCIAL_SIGNUP_URL

  useEffect(() => {
    async function createMemberSession() {
      const accessToken = session?.user?.accessToken
      const refreshToken = session?.user?.refreshToken
      const isCameFromSocialSignUp = document.referrer.includes(
        socialSignUpUrl as string
      )

      // 회원 세션 생성 가능 조건 (이전 페이지가 소셜사인업 + accessToken, refreshToken이 존재 + 로그인 상태가 아닐 때)
      if (
        isCameFromSocialSignUp &&
        accessToken &&
        refreshToken &&
        !isLoggedIn
      ) {
        setIsPending(true)
        createMemberSessionMutate(
          { accessToken, refreshToken },
          {
            onSuccess: async ({ data }) => {
              await updateSession({
                user: {
                  isLoggedIn: true,
                  name: data?.member.name,
                  email: data?.member.email,
                  phone: data?.member.phone,
                  accessToken: data?.accessToken,
                  refreshToken: data?.refreshToken,
                },
              })

              router.replace(router.asPath)

              successCallback?.()
              setIsPending(false) // updateSession으로 회원 정보를 업데이트 한 후 로딩 상태를 해제
            },
            onError: (error) => {
              console.error('createMemberSession Error: ', error)
              errorCallback?.()
              setIsPending(false) // 위 주석과 같은 이유로 onError에도 로딩 상태 해제
            },
          }
        )
      }
    }
    createMemberSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user?.accessToken, isLoggedIn])

  return { isPending }
}
