import { useEffect, useState } from 'react'
import { RecoilValueReadOnly, useRecoilValue } from 'recoil'

export const useSSRRecoilSelector = <RState>(
  defaultValue: RState,
  recoilState: RecoilValueReadOnly<RState>
) => {
  const [isInitial, setIsInitial] = useState(true)
  const value = useRecoilValue(recoilState)

  useEffect(() => {
    setIsInitial(false)
  }, [])

  return isInitial ? defaultValue : value
}
