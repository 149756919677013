import DatePicker from '@src/components/DatePicker'
import { KOR_CALENDAR_TITLE_LIST } from '@src/components/DatePicker/constants'
import { useGetHolidaysMap } from '@src/components/DatePicker/queries'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  formattedTravelEndDateState,
  formattedTravelStartDateState,
  TravelDate,
  travelEndDateState,
  travelStartDateState,
} from '@src/store/atoms/search/date'
import {
  isBasicFlowState,
  isSearchModalOpenState,
  searchTypeState,
} from '@src/store/atoms/search/common'
import { itineraryTypeState } from '@src/store/atoms/search/common'
import { match, P } from 'ts-pattern'
import { useEffect, useState } from 'react'
import { formatDateWithMonthDayAndDayOfWeek } from '@src/utils/date'
import { SearchCard } from './SearchCard'

export const DateSelector = () => {
  const [travelStartDate, setTravelStartDate] =
    useRecoilState(travelStartDateState)
  const [travelEndDate, setTravelEndDate] = useRecoilState(travelEndDateState)
  const itineraryType = useRecoilValue(itineraryTypeState)
  const formattedTravelStartDate = useRecoilValue(formattedTravelStartDateState)
  const formattedTravelEndDate = useRecoilValue(formattedTravelEndDateState)
  const isBasicFlow = useRecoilValue(isBasicFlowState)
  const setSearchType = useSetRecoilState(searchTypeState)
  const setIsSearchModalOpen = useSetRecoilState(isSearchModalOpenState)

  const [roundTripStartDate, setRoundTripStartDate] =
    useState<TravelDate>(travelStartDate)
  const [roundTripEndDate, setRoundTripEndDate] =
    useState<TravelDate>(travelEndDate)

  const { data: holidayMapData } = useGetHolidaysMap()

  const onStartDateChange = (date: Date | null) => {
    if (itineraryType === 'ROUND_TRIP') {
      setRoundTripStartDate(date)
    } else {
      setTravelStartDate(date)
    }
  }

  const onEndDateChange = (date: Date | null) => {
    if (itineraryType === 'ROUND_TRIP') {
      setRoundTripEndDate(date)
    } else {
      setTravelEndDate(date)
    }
  }

  const roundTripRequiredProps =
    itineraryType === 'ROUND_TRIP'
      ? { endDate: roundTripEndDate, onEndDateChange }
      : {}

  const handleSubmit = () => {
    if (itineraryType === 'ROUND_TRIP') {
      setTravelStartDate(roundTripStartDate)
      setTravelEndDate(roundTripEndDate)
    }

    if (isBasicFlow) {
      setSearchType('PERSONNEL')
    } else {
      setIsSearchModalOpen(false)
    }
  }

  const selectedValue = match({
    itineraryType,
    travelStartDate,
    roundTripStartDate,
    roundTripEndDate,
  })
    .with(
      { itineraryType: 'ONE_WAY', travelStartDate: P.when((date) => !!date) },
      ({ travelStartDate }) => {
        if (travelStartDate) {
          return formatDateWithMonthDayAndDayOfWeek(travelStartDate)
        }
      }
    )
    .with(
      {
        itineraryType: 'ROUND_TRIP',
        roundTripStartDate: P.when((date) => !!date),
        roundTripEndDate: P.when((date) => !date),
      },
      ({ roundTripStartDate }) => {
        if (roundTripStartDate) {
          return `${formatDateWithMonthDayAndDayOfWeek(roundTripStartDate)} ~ `
        }
      }
    )
    .with(
      {
        itineraryType: 'ROUND_TRIP',
        roundTripStartDate: P.when((date) => !!date),
        roundTripEndDate: P.when((date) => !!date),
      },
      ({ roundTripStartDate, roundTripEndDate }) => {
        if (roundTripStartDate && roundTripEndDate) {
          return `${formatDateWithMonthDayAndDayOfWeek(roundTripStartDate)} ~ ${formatDateWithMonthDayAndDayOfWeek(roundTripEndDate)}`
        }
      }
    )
    .otherwise(() => '')

  const buttonDisabled = match({
    travelStartDate,
    travelEndDate,
    roundTripStartDate,
    roundTripEndDate,
    itineraryType,
  })
    .with(
      {
        itineraryType: 'ONE_WAY',
        travelStartDate: P.when((date) => !!date),
      },
      () => false
    )
    .with(
      {
        itineraryType: 'ROUND_TRIP',
        roundTripStartDate: P.when((date) => !!date),
        roundTripEndDate: P.when((date) => !!date),
      },
      () => false
    )
    .otherwise(() => true)

  useEffect(() => {
    if (itineraryType === 'ROUND_TRIP') {
      setRoundTripStartDate(travelStartDate)
      setRoundTripEndDate(travelEndDate)
    }
  }, [travelStartDate, travelEndDate, itineraryType])

  return (
    <SearchCard
      title="날짜를 선택해 주세요."
      selectedValue={selectedValue}
      hasFooter
      buttonName="선택 완료"
      buttonDisabled={buttonDisabled}
      onClick={handleSubmit}>
      <DatePicker.TitleList titleList={KOR_CALENDAR_TITLE_LIST} />
      {holidayMapData?.data && (
        <DatePicker
          todayDate={new Date()}
          monthRange={12}
          startDateCharacter="가는날"
          endDateCharacter="오는날"
          todayCharacter="오늘"
          className="tw-p-16 tw-pt-0"
          startDate={
            itineraryType === 'ROUND_TRIP'
              ? roundTripStartDate
              : travelStartDate
          }
          holidays={holidayMapData.data.holidayMap}
          onStartDateChange={onStartDateChange}
          {...roundTripRequiredProps}
        />
      )}
    </SearchCard>
  )
}
