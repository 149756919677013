export const ArrowLeftRightInCircle = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none">
    <path
      d="M14 27.5C6.54416 27.5 0.500001 21.4558 0.500001 14C0.500002 6.54415 6.54416 0.499999 14 0.499999C21.4558 0.5 27.5 6.54416 27.5 14C27.5 21.4558 21.4558 27.5 14 27.5Z"
      fill="white"
      stroke="#E9EBEE"
    />
    <path
      d="M10.6693 17.3332L7.33594 13.9998L10.6693 10.6665L11.6026 11.6165L9.88594 13.3332L18.1026 13.3332L16.3859 11.6165L17.3359 10.6665L20.6693 13.9998L17.3359 17.3332L16.3859 16.3998L18.1026 14.6665L9.88594 14.6665L11.6026 16.3998L10.6693 17.3332Z"
      fill="black"
    />
  </svg>
)
