import { Icon } from '@socar-inc/socar-design-system'
import { DatePickerProps } from '../types'
import { DateMatrix } from './DateMatrix'
import { YearAndMonth } from './YearAndMonth'
import { getDayOfWeek } from '@socar-inc/utils'

export const DateTable = ({
  todayDate,
  startDate,
  endDate,
  monthRange,
  maxSelectedDate,
  isPastDateActive,
  startDateCharacter,
  endDateCharacter,
  todayCharacter,
  holidays,
  handleDateItemClick,
}: DateTableProps) => {
  const currentYear = todayDate.getFullYear()
  const currentMonthIndex = todayDate.getMonth()

  const getHolidayInfo = (dateString: string, description: string) => {
    const targetDate = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`
    const dayOfWeek = getDayOfWeek(new Date(targetDate).getTime())
    const day = dateString[6] === '0' ? dateString[7] : dateString.slice(6, 8)

    return `${day}일(${dayOfWeek}) ${description}`
  }

  return (
    <div className="tw-flex tw-flex-col">
      <div>
        {Array.from({ length: monthRange }, (_, timeRangeIndex) => {
          const accumulatedActualMonths = currentMonthIndex + 1 + timeRangeIndex
          const actualMonth = accumulatedActualMonths % 12 || 12
          const YearsElapsedSinceCurrentYear = accumulatedActualMonths / 12
          const truncatedActualMonth = Math.trunc(YearsElapsedSinceCurrentYear)
          const isDecember = Number.isInteger(YearsElapsedSinceCurrentYear)
          const additionalYear = isDecember
            ? truncatedActualMonth - 1
            : truncatedActualMonth
          const monthlyHolidaysKey =
            String(currentYear + additionalYear) +
            String(actualMonth / 10 < 1 ? `0${actualMonth}` : actualMonth)
          const currentMonthHolidays = holidays && holidays[monthlyHolidaysKey]

          return (
            <div key={timeRangeIndex} className="tw-mb-8 tw-flex tw-flex-col">
              <div className="tw-flex tw-flex-col tw-gap-8">
                <YearAndMonth
                  year={currentYear + additionalYear}
                  month={actualMonth}
                />
                <DateMatrix
                  monthRange={monthRange}
                  maxSelectedDate={maxSelectedDate}
                  startDate={startDate}
                  endDate={endDate}
                  todayDate={todayDate}
                  currentYear={currentYear}
                  todayCharacter={todayCharacter}
                  currentMonthIndex={currentMonthIndex}
                  additionalYear={additionalYear}
                  accumulatedActualMonths={accumulatedActualMonths}
                  timeRangeIndex={timeRangeIndex}
                  isPastDateActive={isPastDateActive}
                  handleDateItemClick={handleDateItemClick}
                  startDateCharacter={startDateCharacter}
                  endDateCharacter={endDateCharacter}
                  holidays={holidays}
                  currentMonthHolidays={currentMonthHolidays}
                />
                {holidays && holidays[monthlyHolidaysKey] && (
                  <div className="tw-mt-10 tw-flex tw-items-center tw-gap-x-16 tw-rounded-8 tw-bg-grey020 tw-px-16 tw-py-14">
                    <Icon
                      icon="ic18_info_line"
                      color="grey050"
                      width={14}
                      height={14}
                    />
                    <div className="tw-grid tw-w-full tw-grid-cols-2 tw-gap-x-20 tw-gap-y-8 tw-text-text-secondary">
                      {holidays[monthlyHolidaysKey].map(
                        ({ date, description }) => (
                          <span
                            key={description}
                            className="title4 tw-font-normal">
                            {getHolidayInfo(date, description)}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export interface DateTableProps
  extends Omit<DatePickerProps, 'onStartDateChange' | 'onEndDateChange'> {
  monthRange: NonNullable<DatePickerProps['monthRange']>
  isPastDateActive: NonNullable<DatePickerProps['isPastDateActive']>
  handleDateItemClick: (date: Date) => void
}
