import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useId,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { twJoin } from 'tailwind-merge'

type PortalType =
  | 'snackbar'
  | 'bottom-sheet'
  | 'floating-button'
  | 'loader'
  | 'modal'

interface PortalProps extends PropsWithChildren {
  type: PortalType
  isOpen: boolean
  /** Portal Container가 생성될 DOM의 selector */
  selector?: string
  /**
   * Dim 표시 여부
   * @default true
   */
  hasDim?: boolean
  /**
   * Portal이 열려있는 동안 body 스크롤 막기
   * @default false
   */
  scrollable?: boolean
  /**
   * mainWrapper, trigger가 아닌 영역(ex: Dim)을 클릭했을 때 Portal 닫기
   * @default false
   */
  closable?: boolean
  /** Portal 닫기 콜백 */
  onClose?: () => void
  /** Portal의 컨텐츠 영역(Dim이 아닌 영역)을 감싸는 Wrapper의 ref */
  mainWrapperRef?: MutableRefObject<HTMLDivElement | null>
  /** 툴팁 버튼과 같은 Trigger의 ref */
  triggerRef?: MutableRefObject<HTMLElement | null>
}

export const Portal = ({
  type,
  children,
  selector,
  isOpen,
  hasDim,
  closable,
  onClose,
  mainWrapperRef,
  triggerRef,
}: PortalProps) => {
  const router = useRouter()

  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const portalId = `portal__${useId()}`

  /**
   * isOpen 값에 따라 Portal Container 생성 or 제거
   */
  useEffect(() => {
    const createPortal = () => {
      // Portal Container로 사용할 DOM 생성
      const newPortalContainer = document.createElement('div')
      newPortalContainer.setAttribute('id', portalId)

      // selector가 존재할 경우 해당 DOM의 자식으로 추가, 존재하지 않을 경우 body의 자식으로 추가
      const parentNode = selector
        ? document.querySelector(selector)
        : document.body

      parentNode?.appendChild(newPortalContainer)
      setContainer(newPortalContainer)
    }

    const removePortal = () => {
      const containerDOM = document.getElementById(portalId)
      containerDOM?.remove()
      setContainer(null)
    }

    isOpen ? createPortal() : removePortal()

    return () => {
      removePortal()
    }
  }, [selector, portalId, isOpen])

  /**
   * mainWrapper, trigger가 아닌 영역(ex: Dim)을 클릭했을 때 Portal 닫기
   */
  useEffect(() => {
    if (!closable) return

    const onScreenClick = ({ target }: MouseEvent | TouchEvent) => {
      if (!(target instanceof Node)) return

      const isMainWrapper = mainWrapperRef?.current?.contains(target)
      const isTrigger = triggerRef?.current?.contains(target)

      if (!isMainWrapper && !isTrigger) {
        onClose?.()
      }
    }

    window.addEventListener('mousedown', onScreenClick, true)
    window.addEventListener('touchstart', onScreenClick, true)
    return () => {
      window.removeEventListener('mousedown', onScreenClick, true)
      window.removeEventListener('touchstart', onScreenClick, true)
    }
  }, [closable, mainWrapperRef, onClose, triggerRef])

  /**
   * Route 변경 시 Portal 닫기
   */
  useEffect(() => {
    if (!onClose) return
    router.events.on('routeChangeStart', onClose)
    return () => {
      router.events.off('routeChangeStart', onClose)
    }
  }, [onClose, router.events])

  return container
    ? ReactDOM.createPortal(
        <>
          {hasDim && <Dim />}
          {children}
        </>,
        container
      )
    : null
}

const Dim = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9997] tw-backdrop-blur-sm"
  />
)
