const TableTitleList = ({ titleList }: { titleList: string[] }) => {
  return (
    <ul className="tw-sticky tw-top-0 tw-z-20 tw-grid tw-h-36 tw-w-[100%] tw-grid-cols-7 tw-grid-rows-1 tw-gap-y-8 tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey030 tw-bg-white">
      {titleList?.map((title, idx) => (
        <li
          style={{
            width: '100%',
          }}
          className={`subtitle2 tw-inline-flex tw-w-44 tw-items-center tw-justify-center tw-text-center ${idx === 0 ? 'tw-text-red050' : ''}`}
          key={title}>
          <span>{title}</span>
        </li>
      ))}
    </ul>
  )
}

export default TableTitleList
