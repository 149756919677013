import { useEffect, useState } from 'react'
import { RecoilState, useRecoilState } from 'recoil'

export const useSSRRecoilState = <RState>(
  defaultValue: RState,
  recoilState: RecoilState<RState>
) => {
  const [isInitial, setIsInitial] = useState(true)
  const [value, setValue] = useRecoilState(recoilState)

  useEffect(() => {
    setIsInitial(false)
  }, [])

  return [isInitial ? defaultValue : value, setValue] as const
}
