import { HolidayDto } from '@src/api'
import type { DateTableProps } from '..'
import { Dates } from './Dates'
import { PrevDates } from './PrevDates'

export const DateMatrix = ({
  startDate,
  endDate,
  todayDate,
  maxSelectedDate,
  currentYear,
  currentMonthIndex,
  additionalYear,
  accumulatedActualMonths,
  timeRangeIndex,
  isPastDateActive,
  startDateCharacter,
  endDateCharacter,
  todayCharacter,
  monthRange,
  currentMonthHolidays,
  handleDateItemClick,
}: DateMatrixProps) => {
  const monthlyActualDate = new Date(currentYear, accumulatedActualMonths)

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-grid tw-grid-cols-7 tw-grid-rows-5 tw-gap-y-4">
        <PrevDates
          currentYear={currentYear}
          currentMonthIndex={currentMonthIndex}
          monthlyActualDate={monthlyActualDate}
        />
        <Dates
          monthRange={monthRange}
          maxSelectedDate={maxSelectedDate}
          todayCharacter={todayCharacter}
          startDateCharacter={startDateCharacter}
          endDateCharacter={endDateCharacter}
          startDate={startDate}
          endDate={endDate}
          todayDate={todayDate}
          monthlyActualDate={monthlyActualDate}
          additionalYear={additionalYear}
          currentYear={currentYear}
          currentMonthIndex={currentMonthIndex}
          timeRangeIndex={timeRangeIndex}
          isPastDateActive={isPastDateActive}
          currentMonthHolidays={currentMonthHolidays}
          handleDateItemClick={handleDateItemClick}
        />
      </div>
    </div>
  )
}

export interface DateMatrixProps extends Omit<DateTableProps, 'timeRange'> {
  currentYear: number
  currentMonthIndex: number
  additionalYear: number
  accumulatedActualMonths: number
  timeRangeIndex: number
  currentMonthHolidays?: HolidayDto[]
}
