import { CTAButton } from '@src/components/ui/CTAButton'
import { ReactNode } from 'react'

interface SearchCardProps {
  title: string
  children: ReactNode
  hasFooter?: boolean
  selectedValue?: ReactNode
  buttonName?: string
  buttonDisabled?: boolean
  onClick?: () => void
}

export const SearchCard = ({
  title,
  children,
  hasFooter,
  selectedValue,
  buttonName,
  buttonDisabled,
  onClick,
}: SearchCardProps) => {
  return (
    <article className="tw-shadow-shadow-basic-y-reverse tw-flex tw-h-full tw-flex-col tw-rounded-16 tw-border-1 tw-border-solid tw-border-grey030 tw-bg-white">
      <header className="tw-px-16 tw-pb-8 tw-pt-24">
        <h1 className="title1 tw-m-0">{title}</h1>
      </header>
      <div className="tw-flex-grow tw-overflow-y-auto">{children}</div>
      {hasFooter && (
        <footer className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-x-16 tw-rounded-b-16 tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey030 tw-bg-white tw-p-16 tw-pt-12">
          <span className="body1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-medium tw-text-text-initial">
            {selectedValue}
          </span>
          <CTAButton
            buttonStyle="primary"
            buttonSize="small"
            fontSize="medium"
            buttonClassName="tw-w-[84px]"
            disabled={buttonDisabled}
            onClick={onClick}>
            {buttonName}
          </CTAButton>
        </footer>
      )}
    </article>
  )
}
