import { DateMatrixProps } from '..'
import { DateItem } from './DateItem'

export const Dates = ({
  startDate,
  endDate,
  todayDate,
  monthlyActualDate,
  currentYear,
  additionalYear,
  currentMonthIndex,
  timeRangeIndex,
  isPastDateActive,
  startDateCharacter,
  todayCharacter,
  endDateCharacter,
  maxSelectedDate,
  monthRange,
  currentMonthHolidays,
  handleDateItemClick,
}: DatesProps) => (
  <>
    {Array.from(
      {
        length: new Date(
          monthlyActualDate.getFullYear(),
          monthlyActualDate.getMonth(),
          0
        ).getDate(),
      },
      (_, dateItemIndex) => (
        <DateItem
          monthRange={monthRange}
          key={`${timeRangeIndex}-${dateItemIndex}`}
          maxSelectedDate={maxSelectedDate}
          startDate={startDate}
          endDate={endDate}
          todayDate={todayDate}
          currentYear={currentYear}
          additionalYear={additionalYear}
          currentMonthIndex={currentMonthIndex}
          timeRangeIndex={timeRangeIndex}
          dateItemIndex={dateItemIndex}
          isPastDateActive={isPastDateActive}
          startDateCharacter={startDateCharacter}
          todayCharacter={todayCharacter}
          endDateCharacter={endDateCharacter}
          currentMonthHolidays={currentMonthHolidays}
          handleDateItemClick={handleDateItemClick}
        />
      )
    )}
  </>
)

export interface DatesProps
  extends Omit<DateMatrixProps, 'accumulatedActualMonths'> {
  monthlyActualDate: Date
}
