import { DateTable } from './DateTable'
import TableTitleList from './DateTable/TableTitleList'
import { DatePickerPropsExtended } from './types'

const DatePicker = ({
  todayDate,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  monthRange = 6,
  maxSelectedDate = 14,
  isPastDateActive = false,
  isOnlySetInRange = true,
  startDateCharacter,
  endDateCharacter,
  todayCharacter,
  holidays,
  ...others
}: DatePickerPropsExtended) => {
  const handleDateItemClick = (date: Date) => {
    // 왕복 선택 시
    if (onEndDateChange) {
      if (startDate && endDate) {
        onStartDateChange(date)
        onEndDateChange(null)
        return
      }

      if (startDate?.valueOf() === date.valueOf()) {
        onEndDateChange(date)
        return
      }

      if (!startDate && !endDate) {
        onStartDateChange(date)
        return
      }

      if (startDate && !endDate) {
        if (startDate.valueOf() >= date.valueOf()) {
          onStartDateChange(date)
          return
        }
        onEndDateChange(date)
      }
    } else {
      // 편도 선택 시
      onStartDateChange(date)
    }
  }

  return (
    <div className="tw-w-full" {...others}>
      <DateTable
        startDateCharacter={startDateCharacter}
        endDateCharacter={endDateCharacter}
        todayCharacter={todayCharacter}
        todayDate={todayDate}
        startDate={startDate}
        endDate={endDate}
        monthRange={monthRange}
        maxSelectedDate={maxSelectedDate}
        isPastDateActive={isPastDateActive}
        isOnlySetInRange={isOnlySetInRange}
        holidays={holidays}
        handleDateItemClick={handleDateItemClick}
      />
    </div>
  )
}

export default Object.freeze(
  Object.assign(DatePicker, {
    TitleList: TableTitleList,
  })
)
