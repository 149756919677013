interface YearAndMonthProps {
  year: number
  month: number
}

export const YearAndMonth = ({ year, month }: YearAndMonthProps) => (
  <div className="tw-flex tw-items-center tw-justify-between tw-pb-8 tw-pt-16">
    <p className="title2 tw-text-grey070">{`${year}년 ${month}월`}</p>
  </div>
)
