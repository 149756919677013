import { Portal } from '@src/components/common/Portal'

import {
  isSearchModalOpenState,
  searchTypeState,
} from '@src/store/atoms/search/common'
import { useRecoilValue } from 'recoil'
import { match, P } from 'ts-pattern'
import { SearchType } from './constants'
import { TravelDestinationSelector } from './TravelDestinationSelector'
import { DateSelector } from './DateSelector'
import { PersonnelSelector } from './PersonnelSelector'
import { useEffect } from 'react'

const Selector = ({ searchType }: { searchType: SearchType }) =>
  match(searchType)
    .with(
      P.when(
        (searchType) => searchType === 'DEPARTURE' || searchType === 'ARRIVAL'
      ),
      () => <TravelDestinationSelector />
    )
    .with(
      P.when(
        (searchType) => searchType === 'ONE_WAY' || searchType === 'ROUND_TRIP'
      ),
      () => <DateSelector />
    )
    .with('PERSONNEL', () => <PersonnelSelector />)
    .otherwise(() => null)

export const SearchModal = () => {
  const searchType = useRecoilValue(searchTypeState)
  const isSearchModalOpen = useRecoilValue(isSearchModalOpenState)

  useEffect(() => {
    if (isSearchModalOpen) {
      document.body.style.overflow = 'hidden'
      return
    }

    document.body.style.overflow = 'auto'
  }, [isSearchModalOpen])

  return (
    <Portal
      type="modal"
      isOpen={isSearchModalOpen}
      selector="#default-layout"
      hasDim
      closable>
      <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9998] tw-mx-auto tw-max-w-[420px] tw-p-16 tw-pt-72">
        <Selector searchType={searchType} />
      </div>
    </Portal>
  )
}
